@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@400;600&display=swap');

.amenity-label {
    color: black; /* Default text color */
    transition: color 0.2s ease-in-out;
  }
  
  /* Checked state - change label color */
  .amenity-checkbox:checked + .amenity-label {
    color: rgba(253, 92, 17, 1);
    font-weight: bold;
  }
  
  /* Custom checkbox styling */
  .amenity-checkbox {
    appearance: none;
    width: 18px;
    height: 18px;
    border: 2px solid black;
    border-radius: 4px;
    display: inline-block;
    position: relative;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  }
  
  /* Change checkbox color when checked */
  .amenity-checkbox:checked{
    background-color: rgba(253, 92, 17, 1);
    border-color: rgba(253, 92, 17, 1);
    background-image: url('../../Assets/checkimage.png');
    background-position: center;
    background-repeat: no-repeat;
  }
  .new-font {
    font-family: 'Barlow', sans-serif;
  }
  