

.container{
  background-color: white;
  inset: 0;
  position: fixed;
  z-index: 999;
  padding: 0.5rem 0.5rem;
  overflow: hidden;
  touch-action: none;
  height: 100vh;
  width: 100vw;
}

.heading {
  font-size: 18px;
  font-weight:  600;
  display: flex;
  align-items: center;
  gap: 0.5rem;

}

.heading i {
  font-size: 1.4rem;  /* Increase icon size */
  font-weight: 900; /* Make icon bold (if supported) */
}
/* date range */
.calendar-div{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

/* @media screen and (min-width: 1080px) { 
  .daterangepicker { 
    display: none !important;
    visibility: hidden !important;
  }

} */

@media screen and (max-width: 480px) {


.daterangepicker {
  transform: translateX(0%);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2) !important;
  border-radius: 20px !important;
  width: 100vw !important;
  overflow: hidden;
  height: 800px;
  display: block;
  visibility: visible;
}
.daterangepicker_input {
  width: 12rem;
  padding: 8px 8px;
  height: 2rem;
  font-size: 16px;
  border: 2px solid #ccc;
  border-radius: 5px ;
  background-color: #fff;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  outline: none ;
  display: none;
  visibility: hidden;
}

/* On focus */
.daterangepicker_input:focus {
  border-color: #007bff;
  box-shadow: 0px 4px 8px rgba(0, 123, 255, 0.2);
  outline: none;
  border: none;
}
/* Adjust each calendar panel (left and right calendars) */
.daterangepicker .drp-calendar {
  width: 100%  !important; 
  height: 600px !important;
}

/* Increase table size inside the calendar */
.daterangepicker .calendar-table {
  font-size: 22px !important; /* Make the text bigger */
  width: 95vw !important;
}

/* Increase size of each date cell */
.daterangepicker td,
.daterangepicker th {
  width: 90px !important;
  height: 40px !important;
  font-size: 14px !important;   
  padding: 0 !important;
}
.daterangepicker .drp-calendar.left {
  height: 21rem !important;
}

/* Make the month and year text bigger */
.daterangepicker .month {
  font-size: 18px !important}

}