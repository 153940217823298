@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
/* Hostel Page */

.whole-page {
  display: flex;
  flex-direction: column;
  width: 96rem;
  margin: 0 auto;
}
 
.image-slider {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.inner-image-slider{
  width: 95%;
  /* display: block; */
}
.inner-image-slider .slick-slider{
  display: none !important;
}
.image-loader-div{
display: flex;
gap: 0.5rem;
}

.image-placeholder-mobile {
  width: 480px;
  height: 450px;
  background: #f0f0f0;
  animation: shimmer 1.5s infinite;
}
.image-placeholder {
  width: 100%;
  height: 100%;
  /* width: 38rem;
  height: 27rem; */
  background: #f0f0f0;
  animation: shimmer 1.5s infinite;
  border-radius: 1rem;
}

@keyframes shimmer {
  0% { background-position: -200% 0; }
  100% { background-position: 200% 0; }
}

.animate-shimmer {
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: shimmer 2s infinite linear;
}
.nav-below-cont{
  width: 100%;
}
.nav-div {
  background-color: #fd590c;
  height: 100px;
  display: flex;
  align-items: center;
  padding-left: 4rem;
  gap: 4rem;
  flex-wrap: wrap;
  margin-bottom: 30px;
}
.nav-text {
  font-size: 20px;
  font-weight: 700;
  border: none;
  background-color: transparent;
  color: white;
}

.about-book{
  padding: 0 4rem;
  width: 100%;
}
.pets {
  height: 100px;
  display: flex;
  align-items: center;
}
.pet-div {
  display: flex;
  gap: 20px;
  margin: 0 10px;
}
.pet-btn {
  height: 48px;
  padding: 0 12px;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 500;
  border-radius: 12px;
  background: #fff;
  border: 1px solid #c4c4c4;
  box-shadow: 0px 4px 4px 0px #00000040;

  display: flex;
  gap: 10px;
  align-items: center;
}
.pet-btn img {
  width: 36px;
  height: 34px;
}

.pros-cons-container {
  display: flex;
  gap: 60px;
  padding: 40px 0;
  width: 100%;
  height: auto;
}
.pros-cons {
  height: auto;
  width: 480px;
  padding: 15px 16px;
  border-radius: 12px;
  box-shadow: 0px 4px 4px 0px #00000040;

}
.pros-cons h4 {
  font-family: Montserrat;
  font-weight: 700;
}
.pros-cons p {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.07px;
}
.pros {
  border: 3px solid #08b60e;
}
.cons {
  border: 4px solid #ed452f;
}
.table-main-container {
  margin: 0px 4rem;
}
.offer-heading-container {
  height: 80px;
  display: flex;
  align-items: center;
  gap: 5rem;
  padding: 0 40px;
  margin-bottom: 20px;
}
#rules-head{
  font-size:25.5px;
  color:#fd590c;
  
}
#rules-sub{
  font-family: "Montserrat", sans-serif; /* Added sans-serif as a fallback */
  font-weight: 600;
  font-size: 20px;
  line-height: 24.38px;
  width: 169px;
  height: 18px;
  color: black;
  top: 215px; /* Optional */
  left: 0px; /* Optional */
  white-space: nowrap;
}

.place-offers {
  font-size: 20px;
  font-weight: 700;
  line-height: 24.38px;
  color: #a2a2a2;
  cursor: pointer;
  transition: 0.2s ease all;
}
.place-offers.active {
  color: #fd590c;
  font-size: 1.5rem;
  font-weight: 800;
  line-height: 39.01px;
  position: relative ;

}

.place-offers.active::after {
  content: "";
  width: 50%;
  position: absolute;
  top: 90%;
  height:4px;
  background-color: #fd590c;
  left:25%;
  border-radius: 10px;
}

.offer-container {
  width: 100%;
  height: 414px;
  border-radius: 20px;
  border: 1px solid #c8c8c8;
  box-shadow: 0px 4px 4px 0px #00000040;
  background: #f6f6f694;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.offers {
  display: flex;
  max-height: 25%;
}
.offer-heading {
  width: 25%;
  height: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 20px;
  padding-left: 40px;
}
.offer-heading img {
  width: 26px;
  height: 26px;
}
.offer-heading h3 {
  color: #11648d;
  font-size: 26px;
  font-weight: bold;
  margin: 0;
}
.offerings {
  width: 75%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 14px;
  padding-left: 20px;
}
.offerings-button {
  border: 1px solid #a2a2a2;
  background: #fff;
  box-shadow: 0px 4px 4px 0px #00000040;
  width: auto;
  height: 44px;
  border-radius: 12px;
  font-size: 17px;
  font-weight: 400;
  line-height: 20.72px;
  padding: 0 20px;
}
.hr {
  width: 94%;
  border: 1px solid #a2a2a2;
  box-shadow: 0px 4px 4px 0px #00000040;
  height: 2px !important;
  margin: 0px auto;
}
.vertical-hr {
  position: absolute;
  width: 360px;
  border: 1px solid #a2a2a2;
  height: 2px !important;
  margin: 0px auto;
  rotate: 90deg;
  bottom: 50%;
  left: 11.5%;
}

.room-name-heading {
color: black;
  font-size: 20px;
  font-weight:700;
}
.select-room-price {
color: black;
  font-size: 26px;
  font-weight:900;
  display: flex;
  align-items: center;
}
.total-amount-div{
  display: flex;
  align-items: center;
gap: 25px;
}
.total-amount-heading{
  font-weight: 700;
  font-size: 17px;
}
.total-amount{
  font-weight: 700;
  font-size: 17px;
display: flex;
align-items: center;
text-align: center;
}
.experience-div{
  background-color: #FAFAFA;
  width: 100%;
  height: 27rem;
  display: flex;  
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
margin-bottom: 1rem;
}

.exp-image-inner-div{
  display: flex;
  flex-direction: row;
  gap:3rem;
  align-items: center;
  justify-content: center;
  transition: all ease-in-out 200ms;
}
.exp-images-div{
  width: 90%;
  gap:1rem;
}
.experience-inner-div{
  display: flex;  
  align-items: center;
  justify-content: center;
  width: 80rem;
  gap: 2rem;
  padding: 0.5rem 0;
}

.exp-main-left-div{
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: end;
}


.experience-left-div-heading{
  font-weight: 700;
  word-spacing: 0.3rem;
  font-size: 3rem;
  font-family: "Poppins",serif ;
  color: black;
}


.exp-slider-left{
  object-fit: cover;
  height: 350px;
  width: 200px;
  border-radius: 50px;
  border: 1px solid gray;
  box-shadow: 0px 4px 15px 8px #00000040;


}
.exp-slider-center-image{
  object-fit: cover;
  height: 400px;
  width: 230px;
  border-radius: 50px;
  border-bottom: 50px;
  padding-bottom: 4px;
  background-color: white;
  z-index: 10;
  position: relative;
  border: 1px solid gray;
  box-shadow: 0px 4px 15px 8px #00000040;

}

.exp-image-heading-sides {
  font-size:0.7rem;
  font-weight: 600 !important;
  position: absolute;
  overflow: hidden;
  padding: 10px;
  top: 80%;
  left: 0%;
  height: 20%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  background: rgb(255,255,255);
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 10%, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.8) 52%);

}


.dest-image-heading-centre{
  font-size: 0.7rem;
  font-weight: 600 !important;
  position: absolute;
  overflow: hidden;
  padding: 10px;
  top: 79%;
  left: 0%;
  height: 20%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  background: rgb(255,255,255);
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 10%, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.8) 42%);

}


.offers-heading{
  font-size:2.5rem;
  font-weight :700;
  font-family: Montserrat ;
  line-height: 2.8rem;
}


.reviews{
margin-top: 2rem;
/* display: none;
visibility: hidden; */
flex-direction: column;
justify-content: center;
height: 70vh;
gap: 2rem;
width: 96%;
position: relative;
margin-bottom:0px;
}
.slider-reviews{
  display: flex;
  width: 80%;
  overflow: hidden;
  height: 85%;
  margin: 0 auto;
  align-items: center;
  margin-bottom:0px;
}
.review-avatar{
  height: 50px;
  width: 50px;
  border-radius: 50%;
  margin: 0 auto;
}
.review-heading{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  font-weight: 700;
  font-family:Montserrat ;
}
.user-review{
  background-color: white;
  border-radius: 16px;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "sora",serif;
    padding: 1rem;
    height:430px;
    border: 1px solid rgba(128, 128, 128, 0.481);
}


.review-para {
  font-size: 16px;
  line-height: 1.2rem;
  font-family: 'Lato', serif;
  font-weight: 500;
  text-align: center;
  /* overflow: hidden; */
  min-height: 10rem; 
  width: 18rem;
  margin-top: 1rem;
}
.user-review-name{
  font-size: 0.9rem;
  color:rgb(81, 81, 81);
}
.slider-reviews .slick-slide {
  margin: 0 27px;
}

.custom-arrow{
height:40px;
width:40px;
border-radius: 10px;
  position: absolute;
  font-size: 20px;
  cursor: pointer;
  background-color: rgba(128, 128, 128, 0.705);
  display: flex;
  justify-content: center;
  align-items: center;
  top: 35%;
}
.custom-arrow:hover{
  background-color: #fd590c;
  transition: ease-in 0.3s;
}
.next{ 
left:92% ;
top: 50%;

}
.prev{ 
  left:5% ;
  top: 50%;
}


.map-div{
margin-top:0px;
margin-bottom: 2rem;
width: 100%;
}

.Location-heading{
  font-size: 2.5rem;
  font-weight: 700;
    font-family: Montserrat;
    margin-bottom: 1rem;
    padding-left:4rem ;
}
.map-container{
border-radius: 1rem;
height: 20rem;
overflow: hidden;
margin: 0 auto;
width: 90rem;
margin-top:0px;
}
#about-des h2{
font-size: 2.5rem;
font-family: "Poppins",serif ;
}

.product-gallery {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: space-around; */
  width: 93%;
  margin: auto;
  padding: 1rem 0;
  gap: 1rem;
}
.main-image{
  width: 38rem;
  height: 27rem;
  cursor: pointer;
}
.main-image img {
  width: 38rem;
  height: 27rem;
  object-fit: cover;
  border-radius: 1rem; 
  padding: 5px;
}

.thumbnail-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 4 images per row */
  gap: 0.5rem;
  max-width: 40%;
  height: 100%;
}
/* .thumbnail:nth-child(1), */
.thumbnail:nth-child(2) {
  grid-column: span 2; /* First row has 2 images */
}

/* Third and Fourth image go in next row */
.thumbnail:nth-child(3),
.thumbnail:nth-child(4) {
  grid-column: span 1;
}

.thumbnail {
  height: 13rem;
  width: 21rem;
  border-radius: 5px;
  overflow: hidden;
  display: flex; 
  align-items: end;
  justify-content: center;
  cursor: pointer;
}

.thumbnail img {
  height: 13rem;
  width: 21rem;
  object-fit: cover;
  border-radius: 1rem;
}

.more-btn{
  grid-column: span 1;
  width: fit-content;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  border-radius: 5px;
  overflow: hidden;
}
.more-btn:hover {
  transform: scale(1.1);
}

/* Media query for smaller screens */
@media (max-width: 1100px) {
  .pets {
    height: auto;
    max-height: 400px;
    /* flex-direction: column; */
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-bottom: 30px;
  }
  .pet-div {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin: 0 10px;
  }
  .pet-btn {
    height: 38px;
    padding: 0 10px;
    font-size: 16px;
  }
  .pet-btn img {
    width: 26px;
    height: 24px;
  }

  .pros-cons-container {
    align-items: center;
  }
  .pros-cons {
    width: 380px;
  }

  .vertical-hr {
    display: none;
  }
  .offer-heading-container {
    justify-content: center;
    
  }
}




@media (max-width: 768px) {
  .slick-list, .slick-track {
    height: auto !important;
    min-height:unset;
  }
  .reviews {
    height: auto !important;
    min-height:unset;
  }

  .slider-reviews {
    height: auto !important;
    min-height: unset !important;
  }

  .user-review {
    display: flex !important;
    flex-direction: column;
    justify-content: center !important;
    align-items: center !important;
    height: 480px !important;
    min-height: unset !important;
  }
  .map-div{
    margin-top:20px;
  }
  .offer-heading-container {
    height: 50px;
    padding: 0 30px;
    margin-bottom: 20px;
  }
  .place-offers {
    font-size: 16px;
  }
  .place-offers.active {
    font-size: 24px;
  }
  .table-main-container {
    margin: 0px 3rem;
  }
  .offer-container {
    width: 100%;
    max-width: 1250px;
    height: 414px;
  }
  .offer-heading {
    width: 30%;
    gap: 10px;
    padding-left: 10px;
  }
  .offer-heading h3 {
    font-size: 16px;
  }
  .offer-heading-img {
    width: 16px;
    height: 16px;
  }
  .offerings {
    width: 70%;
    gap: 7px;
    padding-left: 20px;
  }
  .offerings-button {
    height: 28px;
    font-size: 12px;
    padding: 0 10px;
  }
}

@media (max-width: 480px) {
  .whole-page {
    /* width: 24rem; */
    width: 100%;
    z-index: -1;
    /* margin-top: -10rem; */
  }
  .image-slider{
    display: flex;
    margin-top: -9rem;
    /* background: #000; */
    padding: 0;
  }
  .inner-image-slider{
    /* display: block; */
    width: 100%;
    border-bottom-right-radius: 2rem;
    border-bottom-left-radius: 2rem;
  }
  .inner-image-slider .slick-slider{
    display: block !important;
  }
  .product-gallery{
    display: none;
  }

  #hostelname-mobile{
    margin-top: -5rem;
    color:white;
    z-index: 1;
    position: relative;
    font-family: "Poppins",serif ;
    font-size: 2rem;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
    margin-left: 1rem;
    font-weight: 500;
  }
.pets{
flex-direction: column;
  }
.pet-btn{
  /* display: none; */
  background-color: #fa8a56;
}
.pet-div{
  flex-wrap: wrap;
}
.pros-cons-container{
  padding: 30px 0px;
  flex-direction: column;
  gap: 10px;
}
.pros-cons{
    gap: .5rem;
    display: flex;
    flex-direction: column;
}
  .nav-div{
    display: none;
  }
  .map-container{
    width: auto;
  }
  .booking-section {
    position: sticky;
    bottom: 0; 
    right: 0;
    left: 0;
    flex-direction: column;
    /* top: 20px; */
    background: white;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    padding: 15px;
    z-index: 1050; /* Ensures it's above other content */
    /* max-width: 24rem; */
    width: 100%; 
}
.table-main-container {
  margin: 10px;
}
.offerings-button {
  border: none;
  box-shadow: none;
  background: none;
  font-weight: 600;
}
.exp-slider-center-image{
  height: 20rem;
  width: 12rem;
}
.about-book{
  flex-direction: column;
}
#about-des{
  margin-left: -2rem;
  padding-top: 20px;
}
#about-des h2{
  font-size: 1.5rem;
}
.experience-div{
  height: 100%;
}
.experience-inner-div{
  /* max-width: 24rem; */
  flex-direction: column;
  overflow: hidden;
}
.exp-main-left-div{
  width: 70%;
}
.experience-left-div-heading{
  color:#fd590c;
  font-size: 2.5rem;
  justify-content: center;
  display: flex;
}
.exp-images-div{
  width: 100%;
  padding: 20px 12px;
  overflow-x: auto; /* Adds horizontal scrollbar when content exceeds width */
  white-space: nowrap; /* Prevents content from wrapping */
}

.exp-image-inner-div{
  justify-content: space-evenly;
}
.exp-image-inner-div img{
  box-shadow: 5px 1px 14px 8px #00000040;
}

.dest-text-div{
  gap: 0;
  padding: 0;
  padding-top: 5px;
}
.offers-heading{
  margin-top: 3rem;
}
.pros{
  border: none;
  box-shadow: none;
}
.cons{
  border: none;
  box-shadow: none;
}
.offer-container {
  width: 100%;
  /* width: 23.5rem; */
  /* margin-left: -1.5rem; */
}

 
.offer-container h1 {
  font-size: 12px;
}
#rules-head{
  font-size:14px;
  color:#fd590c;
  
}
.offerings {
  word-wrap: break-word; 
  overflow-wrap: break-word; 
  white-space: normal; 
}

#rules-sub{
  font-size: 11px;
  font-weight: 600;
  overflow-wrap: break-word;
  white-space: normal;
}
.dest-image-heading-centre{
  font-size: 0.7rem;
}
.exp-image-heading-sides{
  font-size: 0.7rem;
}
}
