@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");

body {
  margin: 0;
  font-family: "Raleway", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --primary: #2fc4ed;
  --secondary: #ccbed9;
  --tertiary: #777575;
  --dark: #a34e18;
  --text: #ccbed9;
  --grey: #dcdcdc;
  --white: #ffffff;
  --success: #34a853;
  --pink: #fdf4f4;
  --blackLight: #0d0d0d;
  --black: #000000;
  --red: #ed452f;
  --grey: #cecece;
  --green: #3e9142;
}

::-webkit-scrollbar {
  display: none;
}

.accordion-button {
  background-color: transparent !important;
  font-size: 2rem;
  border: 1px solid white;
  border-radius: 2rem;
}

.main-container {
  margin: 0px 4rem;
}

.left-container {
  margin: 0 4rem;
}

.right-container {
  margin-right: 4rem;
}

.heading-font {
  font-family: "Montserrat", sans-serif;
}

.heading-font-2 {
  font-family: "Raleway";
}

.accordion-button-none .accordion-button::after {
  background-image: none;
  display: none;
}
.experience-heading {
  text-align: left !important; /* Force alignment */
  margin: 0; /* Reset any unwanted margins */
}
.text-white {
  color: var(--white);
}

.bg-white {
  background-color: var(--white);
}
.bg-primary-o {
  background-color: var(--primary);
}

.bg-dark-o {
  background-color: var(--blackLight);
}

.bg-secondary-o {
  background-color: var(--secondary);
}

.bg-tertiary-o {
  background-color: var(--blackLight);
}

.bg-pink {
  background-color: var(--pink);
}

.bg-grey {
  background-color: var(--grey);
}

.bg-green {
  background-color: var(--green);
}
.bg-text-o {
  background-color: var(--text);
}

.text-text {
  color: var(--text);
}

.text-light-gray {
  color: var(--grey);
}

.text-success {
  color: var(--success);
}

.text-primary-o {
  color: var(--primary);
}

.text-secondary-o {
  color: var(--secondary);
}

.text-dark-o {
  color: var(--dark);
}

.text-tertiary-o {
  color: var(--tertiary);
}

.text-black {
  color: var(--black);
}

.text-red {
  color: var(--red);
}

.home-background {
  background: url("./Assets/newHomePageImage.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 90vh;
}

.new-heights-background {
  background-image: url("./Assets/New\ Heights.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: max-content;
  border-radius: 1.5rem;
  padding: 1.5rem;
}

.paradise-background {
  background-image: url("./Assets/Paradise.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: max-content;
  border-radius: 1.5rem;
  padding: 1.5rem;
  display: flex;
  justify-content: end;
}

.life-background {
  background-image: url("./Assets/Life.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: max-content;
  border-radius: 1.5rem;
  padding: 1.5rem;
}

.home-heading {
  font-size: 4rem;
}

.carousel-image {
  width: 300px;
  margin: auto;
}
.carousel-item-padding-10-px {
  padding-left: 10px;   /* Reduce horizontal space between items */
  padding-right: 10px;  /* Reduce horizontal space between items */
}
input[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 0;
  position: absolute;
  left: 0rem;
  width: 100%;
  height: 100%;
}

.custom-date-input {
  position: relative;
}
.custom-search-select .select-search-container .select-search-input {
  width: 25% !important;
}
.custom-dates-index {
  z-index: 10 !important;
}
.book-button {
  background-color: var(--white);
  color: var(--black);
  border-radius: 50%;
  padding: 1rem 1.25rem;
  border: none;
  cursor: pointer;
}

.home-image {
  margin-top: -175px;
}

.card-width {
  width: max-content;
}

.card-content {
  width: 375px;
}

.feature-card {
  border-color: var(--white);
  background: var(--white);
}

.room-card {
  background-color: #ffffff; /* Solid background color */
  border: 1px solid rgba(128, 128, 128, 0.548); /* Initial transparent border */
  border-radius: 1rem; /* Optional: adjust border radius for rounded corners */
  padding: 15px; /* Adjust padding as needed */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
}

.hostel-discover-image {
  width: 90%;
}

#searchbar-nav{
  /* background-color: rgba(42, 42, 42, 0.1); */
  color: rgb(32, 7, 7);
  border-radius: 2rem;
  width: max-content;
  font-family: Montserrat;
  text-transform: capitalize;
  font-weight: bold;
  border-color: rgb(15 15 15);
  justify-content: space-between;
  align-items: center;
}

.search-container-cover{
  padding-top: 3rem;
}
.search-mob-card-cover{
  display: none;
}
.search-bar .select-search-value input{
  width: 100% !important;
}
.search-date-mobile{
  display: none;
}

@media screen and (max-width: 900px) {
  .home-heading {
    font-size: 2.25rem;
  }

  .main-container {
    margin: 0px 3rem;
  }

  .left-container {
    margin: 0 3rem;
  }

  .right-container {
    margin-right: 3rem;
  }

  .margin-top {
    margin: -140px;
  }

  .home-image {
    margin-top: -100px;
  }
}

@media screen and (max-width: 720px) {
  .home-heading {
    font-size: 2.25rem;
  }

  .main-container {
    margin: 0px 3rem;
  }

  .margin-top {
    margin: -140px;
  }

  .home-image {
    margin-top: -75px;
  }

  .card-width {
    width: 100%;
  }

  .card-content {
    width: 100%;
  }

  .accordion-button {
    font-size: 1.5rem;
  }

  .hostel-discover-image {
    width: 80%;
  }
}
@media screen and (max-width: 480px) {
  #search-page-card-def{
    display: none !important;
  }
#search-filter> select {
  padding: 0.3rem 2rem 0.3rem 0.5rem !important;
  font-size: 0.7rem;
}
.search-container-cover{
  padding-top: 1rem ;
}
#search-cards-mob-cover{
  width: 100% !important;
  margin: 0 !important;
}
.search-mob-card-cover{
  display: grid;
}
  #searchbar-nav{
    width: 85%;
    max-width: max-content;
    z-index: 1;
    background-color: #ffffff !important;
    justify-content: unset;
  }
  #searchbar-nav button{
    font-size: 1rem !important;
    padding: 4px 8px !important;
  }
  /* .select-search-value input{
    width: 50% !important;
  } */
  #searchbar-nav ::-webkit-input-placeholder{
    color: #200707;
    font-size: small;
  }
  .custom-dates-index div{
    font-size: 0.6rem;
  }
  .custom-dates-index {
    width: 40%;
  }
  .searchInput-cover{
    max-width: 6rem;
  }
  .search-date-mobile{
    display: inline-block;
  }
}

@media screen and (max-width: 441px) {
  .home-heading {
    font-size: 1.75rem;
  }

  .search-page-container {
    width: 100vw;
  }

  .search-card-image {
    width: 100% !important;
    height: 15rem !important;
  }

  .main-container {
    margin: 0px 1rem;
  }

  .left-container {
    margin: 0 1rem;
  }

  .right-container {
    margin-right: 1rem;
  }

  .home-image {
    margin-top: -50px;
  }

  .accordion-button {
    font-size: 1rem;
  }

  .hostel-discover-image {
    width: 80%;
  }
}