@import url('https://fonts.googleapis.com/css2?family=Antic+Didone&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Antic+Didone&family=Sora:wght@100..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Antic+Didone&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Sora:wght@100..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@300..700&family=Inconsolata:wght@200..900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100..900;1,100..900&display=swap');

/* Main container styles */
.select-search-input {
  color: black !important;
}
.daterangepicker {
  transform: translateX(-25%);
  height: 350px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2) !important;
  border-radius: 20px !important;
}
.daterangepicker_input {
  width: 14rem;
  padding: 8px 12px;
  font-size: 16px;
  border: 2px solid #ccc;
  border-radius: 5px ;
  background-color: #fff;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  outline: none ;
}
.daterangepicker_input::placeholder{
  color: black;
}

/* On focus */
.daterangepicker_input:focus {
  border-color: #007bff;
  box-shadow: 0px 4px 8px rgba(0, 123, 255, 0.2);
  outline: none;
  border: none;
}
/* Adjust each calendar panel (left and right calendars) */
.daterangepicker .drp-calendar {
  width: 350px !important; /* Increase calendar width */
  height: 400px !important; /* Increase calendar height */
}

/* Increase table size inside the calendar */
.daterangepicker .calendar-table {
  font-size: 18px !important; /* Make the text bigger */
}

/* Increase size of each date cell */
.daterangepicker td,
.daterangepicker th {
  width: 40px !important;
  height: 40px !important;
  font-size: 14px !important; 
}

/* Make the month and year text bigger */
.daterangepicker .month {
  font-size: 18px !important;
}

/* Increase button sizes */




.nav-bar {
  display: flex; /* Equivalent to d-flex */
  flex-wrap: wrap; /* Allows wrapping if content overflows */
  gap: 1rem; /* Equivalent to gap-3 */
  align-items: center; /* Equivalent to align-items-center */
  justify-content: space-between; /* Equivalent to justify-content-between */
  padding: 0.2rem 3rem;

}
.nav-bar .select-search-value input{
  width: 70% !important;
  font-size: 1.25rem;
}
.nav-links{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3rem;
}

a {
  text-decoration: none; /* Removes the underline */
  color: inherit; /* Inherits the text color from the parent element */
}
a:hover {
  text-decoration: none; /* Optional: Adds an underline on hover for better UX */
  color: inherit;
}
.single-link{
  font-weight: 500;
  font-size: 1rem;
  font-family: Poppins;
  cursor: pointer;
}
.single-link:hover{ 
  color: #f84e00;
  transition: ease-in-out 0.2s;
}
.host-button {
  background: transparent;
  color: var(--grey);
  border-radius: 1.25rem;
  padding: 0.5rem 1.5rem;
}

.host-button:hover {
  background-color: var(--grey);
  color: var(--dark);
}

.primary-button {
  background: var(--red);
  color: var(--white);
  border-radius: 1.25rem;
  border: 1px solid var(--red);
  padding: 0.5rem 1.5rem;
}

.primary-button:hover {
  background-color: var(--tertiary);
  color: var(--primary);
  border: 1px solid var(--primary);
}

.select-search-container {
  width: 250px !important;
  margin-bottom: 0px !important;
}

ul {
  padding-left: 0px;
}

.select-search-input {
  width: auto !important;
  height: 36px !important;
  padding: 0px !important;
  background-color: transparent !important;
  /* color: var(--dark) !important; */
  color: #200707;
  border-radius: 0px !important;
  border: 0px !important;
  outline: none !important;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  text-transform: capitalize;
}

.select-search-select {
  background: white !important;
  box-shadow: none !important;
  border: 0px !important;
}

.select-search-option,
.select-search-not-found {
  background: white !important;
  color: #200707 !important;
  margin: 0px;
}

.select-search-option:hover {
  background: white !important;
}

.accordion-button:not(.collapsed) {
  background-color: white !important;
  border: 1px solid #776262;
  border-radius: 2rem;
  box-shadow: 0px 4px 4px 0px #00000040;
}

.accordion-button:focus {
  border: 1px solid #776262;
  border-color: white !important;
  box-shadow: none !important;
}
.accordion-item {
  border-radius: 2rem !important;
}

.slick-prev:before,
.slick-next:before {
  font-size: 2.5rem !important;
  color: var(--white) !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slick-prev {
  left: 20px !important;
  z-index: 1;
}

.slick-next {
  right: 16px !important;
  z-index: 1;
}
.slider-main{
  display: flex;
  flex-direction: column;
  justify-content: center;
  }
  .slider{
    display: flex;
    align-items: center;
    justify-items: center;
  height:700px;
  position: relative;
  }
  .slider-image{
    object-fit: cover;
    border-radius:10px;
    width: 1265px;
    height: 665px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5); 
    margin-top: 20px;
  }
  .hero-sec{
    display: block;
  }
  .text-on-image-slider-div{
    position: absolute; 
    top: 30%;   
    left: 45%;
    gap: 2rem;
    text-shadow: 2px 2px 5px rgb(0, 0, 0); 
    transform: translate(-50%, -50%);
    z-index: 100;
    color: white; 
    text-transform: uppercase;
    width: 75%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: end;
  }
  .get-100-off{
    width: 80%;
    color: white;
    font-weight: 500;
    text-transform: initial;
    margin: 0.5rem 0 0 7rem;
    font-family: "Montserrat", sans-serif;
    font-size: 2.2rem;
    text-shadow: 2px 2px 5px rgb(0, 0, 0); 
  }
  .get-100-focus{
    font-size: 2.5rem;
    font-weight: 600;
  }
  .slider-image-heading {
  font-family: Poppins;
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
  }
  .slider-image-para{
    font-size:1.6rem;
    color: rgb(227 227 227);
    font-family: Montserrat;
    font-weight: 600;
    text-align: end;
    margin-top: -5rem !;
  }
  .slider-overlay {
    width: 1265px;
    height: 665px;
    position: absolute;
    /* background-color: rgba(0, 0, 0, 0.5); Black background with 30% opacity */
    border-radius: 10px; /* Make sure the overlay matches the border radius of the image */
    z-index: 100;
  left: 86px; 
    top: 20px; 
  }
.custom-dot {
  display: inline-block;
  margin: 0 5px;
  position: relative;
  width: 10px; 
  height: 10px; 
  background-color:rgba(255, 255, 255, 0.353); 
  border-radius: 50%;
  transition: all 0.3s ease;
  position: absolute;
  top: -15vh;
}
.search-whole-page{
  width: 96rem;
  margin:0 auto;
}

.slick-dots .slick-active .custom-dot {
  background-color:rgb(255, 255, 255); 
  scale: 1.1;
}


.slick-dots .custom-dot:hover {
  background-color: white; 
}



.search-bar-main{
display: flex;
/* justify-content: center; */
align-items: center;
position: relative;
}

.search-bar-main .where{
  width: 13rem;
  padding: 0 5px;
}
.check-in {
  display: flex;
gap: 0.3rem;
  flex-direction: column;
}

.search-bar{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 20px;
  height: 120px;
  width: 52rem;
  min-width: 52rem;
  border-radius: 5px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5); 
  background-color: #f9f9f9;
  /* position: absolute; */
  /* top: -8.5rem; */
  z-index: 2;
}
.search-bar-button{
  background-color: #fd590c;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  text-align: center;
  font-weight: 500;
}

.search-bar-button:hover{
  background-color: #f84e00;

}
.mob-cards-cover{
  display: none;
}
.exp-container{
  display: block;
    }
.exp-head{
  display: none;
}
.exp-para{
  font-size: 3rem;
}
.exp-white-cover{
  justify-content: center;
  opacity: 10%;
  margin-top: -15rem;
}
.exp-mob-white{
  display: none;
}
.properties-main{
width: 100%;
padding: 2rem 0rem;
display: flex;
align-items: center;
justify-content: center;
margin-top: 1rem;
}

.property-list {
width: 75rem;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr); 
  justify-items: center; 
  align-items: center; 
  gap: 1.5rem;
}
.property-list>div{
  cursor: pointer;
}



.tags-div{
  width: 100%;
  display: flex;
  width: 250px;
  display: flex;
  flex-wrap: wrap;
}
.tag{
  background-color: rgba(128, 128, 128, 0.333);
  border-radius: 20px;
  padding: 0.2rem 0.8rem;
  font-size: 13px;
  font-weight: 500;
  margin: 4px;
  color: rgba(0, 0, 0, 0.689);
}

.destination-div{
  background-color: #1d1b1a;
  width: 100%;
  height: 35rem;
  display: none;  
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
}
.destination-inner-div{
  display: flex;  
  align-items: center;
  justify-content: center;
  width: 80rem;
  gap: 3rem;
}

.dest-images-div{
  width: 50%;
  display: flex;
  flex-direction: row;
  gap:1rem;
  transition: all ease-in-out 200ms;
  overflow: hidden;
  align-items: center;
  justify-content: center;

}
.single-dest-image{
  display: flex;
  flex-direction: row;
  gap:1rem;
  align-items: center;
  justify-content: center;
  transition: all ease-in-out 200ms;
}
.dest-slider-left{
  object-fit: cover;
  height: 300px;
  width: 180px;
  border-radius: 25px;
}
.dest-slider-center-image{
  object-fit: cover;
  height: 350px;
  width: 230px;
  border-radius: 20px;
  border-bottom: 5px;
  padding-bottom: 4px;
  background-color: white;
  z-index: 10;
  position: relative;
}
.dest-slider-right{
  object-fit: cover;
  height: 300px;
  width: 180px;
  border-radius: 25px;

}
.dest-image-heading {
  font-size: 20px;
  font-weight: 600 !important;
  position: absolute;
  overflow: hidden;
  padding: 10px;
  top: 79%;
  left: 0%;
  height: 20%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  background: rgb(255,255,255);
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 10%, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.8) 42%);
}
.dest-image-heading-left {
  font-size: 20px;
  font-weight: 600 !important;
  position: absolute;
  overflow: hidden;
  padding: 10px;
  top: 80%;
  left: 0%;
  height: 20%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  background: rgb(255,255,255);
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 10%, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.8) 52%);
}
.dest-image-heading-right {
  font-size: 20px;
  font-weight: 600 !important;
  position: absolute;
  overflow: hidden;
  padding: 10px;
  top: 80%;
  left: 0%;
  height: 20%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  background: rgb(255,255,255);
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 10%, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.8) 52%);
}
.mobile-single-img{
  display: none;
}
.dest-main-left-div{
  width: 42%;
  display: flex;
  align-items: center;
  justify-content: end;

}
.dest-text-div{
  display: flex;
  flex-direction: column;
  color: white;
  width: 35vw;
  gap: 0.8rem;
  padding: 20px;
}
.dest-left-div-heading{
  font-weight: 700;
  word-spacing: 0.3rem;
  font-size: 3rem;
  font-family: "Poppins",serif ;
}
.dest-left-div-heading span{
  padding-right: 8px;
}

 .dest-left-div-sub-heading{
  font-size: 17px;
  line-height: 25px;
  word-spacing: 10px; 
  color:rgb(207, 206, 206);
}
.dest-buttons-div{
  display: flex;
  justify-content: end;
  gap: 1rem;
  margin-right: 2.5rem;
}
.dest-button{
  border-radius: 5px;
}


/* testimonials */
.testimonials-container{
  background-color: white;
  padding: 7rem 5rem ;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.testimonials-texts-div{
  width: 50%;
}
.testimonials-heading{
  color:black;
  font-weight: 700;
  font-size: 4.19rem;
  text-align: center;
  font-family: 'sora', serif;
  line-height: 4.5rem;
}
.testimonials-subheading{
  font-size: 16px;
  margin-top: 24px;
  text-align: center;
  font-family: 'Lato', serif; 
  font-weight: 500;
  line-height: 24px;
}
.users-reviews{
  display: flex;
  gap: 3rem;
  margin-top: 4rem;
}
.single-user-review-orange{
  background-color: #fd590c;
  height: 12rem;
  width: 24.25rem;
  border-radius: 16px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "sora",serif;
box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3); 
position: relative;
}
.user-review-image{
  width: 60px;
  height: 60px;
  border-radius: 50%;
  position: absolute;
  top: -1.8rem;
  object-fit: cover;
}
.single-user-review{
  background-color: white;
  height: 12rem;
  width: 24.25rem;
  border-radius: 16px;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "sora",serif;
box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5); 
position: relative;
}

.user-review-text-div{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
}

.user-review-para {
  font-size: 16px;
  line-height: 1.2rem;
  font-family: 'Lato', serif;
  font-weight: 500;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box; 
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3; 
  width: 20rem; 
  height: auto; 
}




.add-button:hover {
  color: rgba(0, 0, 0, 0.66);
}

.row .col img {
  cursor: pointer;
}

.loading-frame {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: white;
}

.spinner {
  width: 50px;
  height: 50px;
  border: 5px solid rgba(0, 0, 255, 0.2);
  border-top: 5px solid blue;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.loading-text {
  margin-top: 10px;
  font-size: 1rem;
  color: black;
  font-family: Arial, sans-serif;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.left-container .click {
  cursor: pointer;
  min-height: 400px;
}

.d-flex .rounded {
  box-shadow: rgba(17, 17, 17, 0.35) 0px 3px 7px;
}

.property-box {
  border-radius: 0.8rem;
  margin-bottom: 8px;
  border: 1px solid #ccc;
  box-shadow: 0px 4px 4px 0px #00000040;
}

.banner-w {
  width: 40%;
}

.card-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem 1rem;
}

/* FAQ image and accordion */
.accordion-item {
  border: 1px solid #ccc;
}
.accordion {
  width: 750px;
}
.accordion-body {
  padding: 1rem 2.25rem;
}
.accordion-item:first-of-type .accordion-button {
  border-radius: 32px !important;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
}
.accordion-item:not(:first-of-type) {
  border-top: 1px solid #ccc !important;
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border: transparent !important;
}
.bi-plus-circle-fill::before {
  color: #FE7524;
  content: "\f4f9";
}
.accordion-button:not(.collapsed) {
  color: #FE7524 !important;
  border: none;
}

.faq-div {
  display: flex;
  justify-content: center;
  gap: 50px;
}
.faq-img-div {
  height: 375px;
  width: 625px;
}
.faq-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: left;
}

.place-name {
  font-size: 24px;
  width: 124px;
  text-align: center;
}

.place {
  flex-direction: column;
}
.place img {
  border-radius: 26px;
  border: none;
  box-shadow: 0px 4px 4px 0px #00000040;
  height: 150px;
  width: 150px;
}
.mobile-searchbar{
  display: none;
}
/* smooth transition */
.smooth-transition {
  transition: all 2.5s ease-in-out;
  opacity: 0;
  transform: translateY(-10px);
}

.smooth-transition.active {
  opacity: 1;
  transform: translateY(0);
}



@media (min-width: 1470px) {
  .accordion {
    width: 800px;
  }
}

@media (max-width: 900px) {
  .search-whole-page{
    width: auto;
  }
  .banner-w {
    width: 100%;
  }
  .accordion {
    width: 100%;
  }
  .faq-img-div {
    display: none;
  }
  .none {
    display: none !important;
  }
  .property-list {
      width: fit-content;
      grid-template-columns: repeat(2, 1fr); 
     
    }
    .tab-none{
      display: none;
    }
    .get-100-off{
      font-size: 2rem;
    }
    .get-100-focus{
      font-size: 2.2rem;
      font-weight: 600;
    }
    .text-on-image-slider-div{
      top: 40%;
    }
}

.property-slider{
  display: none;
  visibility: hidden;
  overflow: hidden;
  width: 100vw;
    }  

@media screen and (max-width: 480px) {
  .search-whole-page{
  width: 100vw;
  margin:0 auto;
}
.hero-sec{
  display: none;
}
.mobile-searchbar{
  display: flex;
}
.nav-bar .select-search-value input{
  width: 45% !important;
}
.search-bar-mobile{
  display: flex;
  padding-left: 1rem;
  width: 100%;
}
.search-bar-mobile .select-search-value input{
  width: 100% !important;
}
.search-bar-inner{
  display: flex;
  justify-content: center;
  align-items: start;
  width: 100%;
}

.text-on-image-slider-div{
  top:30%;
  color: black;
  gap: 0rem;
  width: 85%;
}
.slider-image-para{
  color:rgb(255 255 255);
  font-weight: 600;
}
  .nav-bar{
    padding: 0px 10px;
    gap: 1.5rem;
   flex-wrap: nowrap;
   flex-direction: column;
   align-items: start;
  }
  .nav-bar a{
    z-index: 1;
    position: relative;
  }

 .search-bar-main{
    margin-top: 0;
    padding:  0 0.5rem;
    display: none;
    }
  .search-bar{
    flex-direction: column;
    height: max-content;
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* align-items: start; */
    padding: 0rem 1rem;
    max-width: 100%;
    position: static; 
    top: auto; 
    width: max-content;
    border-radius: 10rem;
  }
.custom-dot{
  display: none;
}

.where{
display: flex;
flex-direction: column;
justify-content: center;
align-items: start;
border-bottom: 2px solid rgba(0, 0, 0, 0.299);
width: 5rem;
/* line-height: 3rem; */
}

.check-out{
/* border-bottom: 2px solid rgba(0, 0, 0, 0.299); */
width: 6rem;
display: none;
}
.check-in{
  /* border-bottom: 2px solid rgba(0, 0, 0, 0.299); */
  width: 6rem;
display: none;

}
/* dsd */
.search-bar-button{  
display: none;
}
  .property-list {
      display: none;
      visibility: hidden;
    }
.exp-container{
  display: block;
    }
.exp-head{
  display: block;
}
.exp-para{
  font-size: 1.5rem;
}
.exp-white-cover{
  margin-top: -5rem;
}
.exp-mob-white{
  display: flex;
}
.mob-cards-cover{
  display: block;
}

.property-slider{
  height: 28rem;
  /* margin-top: -2rem; */
  /* display: block; */
  display: none;
  visibility: visible;
    }
    
 .mob-none{
  display: none !important;
 }

    .custom-arrow {
      position: absolute;
      top: 28%;
      transform: translateY(-50%);
      background-color: rgba(113, 113, 113, 0.5);
      color: white;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      z-index: 10;
    }
    
    .custom-arrow.prev {
      left: 10px; /* Adjust as needed */
    }
    
    .custom-arrow.next {
      right: 10px; /* Adjust as needed */
    }
    
    .custom-arrow .arrow-content {
      font-size: 16px;
      font-weight: bold;
    } 
    .slick-slide {
      /* padding: 0 10px; */
      box-sizing: border-box; 
      /* margin-left: 10px; */
      /* padding: 10px; */
    }

    /* destination div responsive */
#explore {
  margin-top: 0 !important;
}
#explore h2{
  font-family: "Sora";
  font-size: 2rem;
}
#explore>div>div{
  display: none;
}
.mobile-single-img{
  display: block;
}
    .destination-div{
      height: 40rem;
      display: none;
    }
    .destination-inner-div{
      flex-direction: column;   
      padding: 0;   
      width: 100vw; 
      height: 100%;
      /* padding: 5rem 0  1rem 0 ; */
      gap: 0rem;
     }
  

  .dest-main-left-div{
    width: 100%;
    justify-content: center;
  }
  .dest-text-div{
    padding: 5px;
    width: 100%;
    gap: 1rem;
    padding: 0 0.5re;
  }
  .dest-left-div-heading{
    font-size: 2rem;
    text-align: center;
    line-height: 3rem;
  }
  .dest-left-div-sub-heading{
    text-align: center;
    word-spacing: 2px;

  }
  .dest-buttons-div{
    width: 100%;
    justify-content: center;
  }

  .dest-slider-left{
    height: 250px;
    width: 150px;
  }
  .dest-slider-center-image{
    height: 300px;
    width: 200px;
  }
  .dest-slider-right{
    height: 250px;
    width: 150px;
  
  }
  .testimonials-container{
    align-items: normal;
  }
  .users-reviews-cover
  {
    overflow-y: auto;
    padding: 1rem 0;
  }
  .users-reviews{
    display: flex;
    gap: 3rem;
    flex-direction: row;
    margin-top: 4rem;
    padding: 0 1rem ;
    align-items: center;
    /* justify-content: center; */
  }
  .single-user-review-orange h1{
    font-size: 1rem !important;
  }
  .single-user-review-orange p{
    font-size: 0.625rem !important;
  }
  .single-user-review-orange div{
    font-size: 8px !important;
  }
  .single-user-review-orange{
    height: 8rem;
    min-width: 12rem;
  }
  .single-user-review{
    width: 12rem;
    height: 8rem;
    min-width: 12rem;
    justify-content: unset;
  }
  .user-review-image{
    top: -3.6rem;
    width: 5rem;
    height: 5rem;
  }
  .user-review-para {
    width: 90%;
  -webkit-line-clamp: 2;
  }
  .user-review-text-div{
    margin-top: 1.5rem;
  }
  .user-review-text-div h1{
    font-size: 1rem !important;
  }
  .user-review-text-div p{
    font-size: 0.625rem !important;
  }
  .user-review-text-div div{
    font-size: 8px !important;
  }
.testimonials-container{
  /* padding: 1rem 0.5rem; */
  padding: 1rem 0.5rem 2rem 0.5rem;
  gap: 0;
}
  
.testimonials-texts-div{
  width: 100%;
  padding-top: 4rem ;
}
.testimonials-heading{
  font-size: 1.625rem;
  text-align: center;
  font-family: 'sora', serif;
  line-height: 2.5rem;
  font-weight: 600;
}
.testimonials-subheading{
  font-size: 13px;
  margin-top: 24px;
  text-align: center;
  font-family: 'Lato', serif; 
  font-weight: 500;
  line-height: 1.2rem;
}
.slider-main{
  flex-direction: row;
  margin-top: -6rem;
  display: none;
  overflow: hidden;
  /* background-color: #060708; */
 }
.slider{
  height: auto;
  padding: 0.5rem 0;
  overflow: hidden;

}

.slider-image{
  width:100vw;
  height: 110vw;
}
.slider-overlay {
  width:90vw;
  height: 70vw;
  inset: 0;
  top: 1.8rem;  
  left: 0.5rem;
}
.slider-image-heading {
    font-size: 1.5rem;
    text-align: center;
    text-shadow: 2px 2px 5px rgb(255 255 255);;
  }
  
}

.nav-links{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  display: none;
  visibility: hidden;
}
